/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable default-case */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
/** カルモ定数 */
namespace NEWCAR_CONST {
  /* クイック審査 */
  export namespace QUICK {
    export const ID = {
      EASY: '10053',
      RELIEF: '10013',
    };

    export const MEMO = '|グレード：未選択|ボディカラー：未選択|カーナビ：未選択|ETC：未選択|バックカメラ：未選択|フロアマット：未選択|希望ナンバー：未選択|ドアバイザー：未選択|期間：未選択|メンテ：未選択|もらえるオプション：希望';
  }

  /* オススメの車ランキング */
  export namespace RANKING {
    export const ID = {
      NO1: '10013',
      NO2: '10017',
      NO3: '10030',
    };
  }

  /* 人気の車ランキング */
  export namespace POPULERRANKING {
    export const ID = {
      NO1: '10013',
      NO2: '10030',
      NO3: '10017',
      NO4: '10031',
      NO5: '10053',
    };
  }

  /* 燃費が良い車ランキング */
  export namespace FUELRANKING {
    export const ID = {
      NO1: '10053',
      NO2: '10017',
      NO3: '10141',
      NO4: '10138',
      NO5: '10034',
    };
  }

    /* 新車TOPランキング */
    export namespace TOPRANKING {
      export const ID = {
        miraes: '10017',
        tanto: '10014',
        nbox: '10013',
        none: '10095',
        hustler: '10030',
        spacia: '10031',
        spaciacustom: '10057',
        alt: '10053',
        altlapin: '10034',
        wagonr: '10016',
        wagonrsmile: '10195',
        ekwagon: '10049',
        delicamini: '10212',
      };
    }

  /**
   * 月額費用内訳の固定値
   */
  export namespace BREAKDOWN {
    /** お客様サポート料 */
    export const CUSTOMER_SUPPORT_FEE = 100;
  }

  export namespace FORM {
    /**
     * 審査申込入力データのローカルストレージのキー
     */
    export const ENTRY_FORM_LOCAL_STORAGE_KEY = 'carmo';
    /**
     * 審査申込入力データのバージョン
     */
    export const ENTRY_FORM_VERSION = '20190327';
    export const OLDEST_AGE = 75;
    export const YOUNGEST_AGE = 18;
    export const UNEMPLOYED_VALUE = 3;
    /**
     * 審査申込み TODO 新車で使用しないタイプがないか確認
    */
    export type ApplicationType = 'DEFAULT' | 'CHOICE' | 'MACH' | 'QUICK' | 'UNUSED' | 'CONSULT' | 'OWN_STOCK';
  }

  export namespace SIMULATIONRESULT {
    /**
     * 料金シミュレーション保存時のローカルストレージのキー
     */
    export const SIMULATION_RESULT_LOCAL_STORAGE_KEY = 'simulations';
  }

  /** 掲載ステータス */
  export namespace PUBLISH {
    /**
     * ステータス
     * @export
     * @enum {number}
     */
    export enum STATUS {
      /** 販売停止中 */
      SUSPENDED = 0,
      /** 販売中 */
      SALE = 1,
    }
  }

  /** 期間 */
  export namespace TERM {
    export enum ID {
      /** 1年 */
      YEAR_1 = 1,
      /** 2年 */
      YEAR_2 = 2,
      /** 3年 */
      YEAR_3 = 3,
      /** 4年 */
      YEAR_4 = 4,
      /** 5年 */
      YEAR_5 = 5,
      /** 6年 */
      YEAR_6 = 6,
      /** 7年 */
      YEAR_7 = 7,
      /** 8年 */
      YEAR_8 = 8,
      /** 9年 */
      YEAR_9 = 9,
      /** 10年 */
      YEAR_10 = 10,
      /** 11年 */
      YEAR_11 = 11,
    }

    /** 表示名
     * TERM.IDを指定
     */
    export const NAME: { [key: number]: string } = {
      1: '1年',
      2: '2年',
      3: '3年',
      4: '4年',
      5: '5年',
      6: '6年',
      7: '7年',
      8: '8年',
      9: '9年',
      10: '10年',
      11: '11年',
    };
    export function NAME_TO_ID(name: string): ID {
      if (name === NAME[ID.YEAR_1]) {
        return ID.YEAR_1;
      }
      if (name === NAME[ID.YEAR_2]) {
        return ID.YEAR_2;
      }
      if (name === NAME[ID.YEAR_3]) {
        return ID.YEAR_3;
      }
      if (name === NAME[ID.YEAR_4]) {
        return ID.YEAR_4;
      }
      if (name === NAME[ID.YEAR_5]) {
        return ID.YEAR_5;
      }
      if (name === NAME[ID.YEAR_6]) {
        return ID.YEAR_6;
      }
      if (name === NAME[ID.YEAR_7]) {
        return ID.YEAR_7;
      }
      if (name === NAME[ID.YEAR_8]) {
        return ID.YEAR_8;
      }
      if (name === NAME[ID.YEAR_9]) {
        return ID.YEAR_9;
      }
      if (name === NAME[ID.YEAR_10]) {
        return ID.YEAR_10;
      }
      return ID.YEAR_11;
    }

    export function TO_MONTHS(term?: ID): number | undefined {
      return term && term * 12;
    }

    export function MAX(): number {
      const terms = Object.keys(ID).filter((key: string) => !isNaN(Number(key))).map((term: string) => Number(term));
      return terms.reduce((a, b) => Math.max(a, b));
    }
  }

  /** 価格 */
  export namespace PRICE {
    export enum ID {
      /** 10-20K */
      BW_10_20K = 1,
      /** 20-30K */
      BW_20_30K = 2,
      /** 30-40K */
      BW_30_40K = 3,
      /** 40-50K */
      BW_40_50K = 4,
      /** 50K超 */
      OVER_50K = 5,
      /** 50K超 */
      OVER_60K = 6,
      /** 50K超 */
      OVER_70K = 7,
      /** 50K超 */
      OVER_80K = 8,
    }

    /** 表示名
     * 順序がリスト順番に相当
     */
    export const NAME: { [key: number]: string } = {
      1: '1万円〜',
      2: '2万円〜',
      3: '3万円〜',
      4: '4万円〜',
      5: '5万円〜',
      6: '6万円〜',
      7: '7万円〜',
      8: '8万円〜',
    };

    /**
     * 期間別で利用する値段の範囲
     * @export
     * @param {TERM.ID} key
     * @returns {number[]}
     */
    export function TERM_TO_PRICE_RANGE(key: TERM.ID): number[] {
      switch (key) {
        case TERM.ID.YEAR_11:
        case TERM.ID.YEAR_10:
        case TERM.ID.YEAR_9:
        case TERM.ID.YEAR_8:
        case TERM.ID.YEAR_7:
        case TERM.ID.YEAR_6:
        case TERM.ID.YEAR_5:
          // 9〜5年：1万〜、2万〜、3万〜、4万〜、5万〜
          return [
            PRICE.ID.BW_10_20K,
            PRICE.ID.BW_20_30K,
            PRICE.ID.BW_30_40K,
            PRICE.ID.BW_40_50K,
            PRICE.ID.OVER_50K,
          ];
        case TERM.ID.YEAR_4:
        case TERM.ID.YEAR_3:
        case TERM.ID.YEAR_2:
          // 4〜2年：2万〜、3万〜、4万〜、5万〜、6万〜
          return [
            PRICE.ID.BW_20_30K,
            PRICE.ID.BW_30_40K,
            PRICE.ID.BW_40_50K,
            PRICE.ID.OVER_50K,
            PRICE.ID.OVER_60K,
          ];
        case TERM.ID.YEAR_1:
          // 1年：4万〜、5万〜、6万〜、7万〜、8万〜
          return [
            PRICE.ID.BW_40_50K,
            PRICE.ID.OVER_50K,
            PRICE.ID.OVER_60K,
            PRICE.ID.OVER_70K,
            PRICE.ID.OVER_80K,
          ];
      }
    }

    /**
     * 期間別でどの値段がどのレンジとして計上するか
     * @export
     * @param {TERM.ID} key
     * @param {number} taxIncludedPrice
     * @returns {PRICE.ID}
     */
    export function TERM_PRICE_TO_ID(
      key: TERM.ID,
      taxIncludedPrice: number,
    ): PRICE.ID {
      switch (key) {
        case TERM.ID.YEAR_11:
        case TERM.ID.YEAR_10:
        case TERM.ID.YEAR_9:
        case TERM.ID.YEAR_8:
        case TERM.ID.YEAR_7:
        case TERM.ID.YEAR_6:
        case TERM.ID.YEAR_5:
          // 11〜5年：1万〜、2万〜、3万〜、4万〜、5万〜
          if (taxIncludedPrice >= 50000) {
            return PRICE.ID.OVER_50K;
          }
          if (taxIncludedPrice >= 40000) {
            return PRICE.ID.BW_40_50K;
          }
          if (taxIncludedPrice >= 30000) {
            return PRICE.ID.BW_30_40K;
          }
          if (taxIncludedPrice >= 20000) {
            return PRICE.ID.BW_20_30K;
          }
          return PRICE.ID.BW_10_20K;

        case TERM.ID.YEAR_4:
        case TERM.ID.YEAR_3:
        case TERM.ID.YEAR_2:
          // 4〜2年：2万〜、3万〜、4万〜、5万〜、6万〜
          if (taxIncludedPrice >= 60000) {
            return PRICE.ID.OVER_60K;
          }
          if (taxIncludedPrice >= 50000) {
            return PRICE.ID.OVER_50K;
          }
          if (taxIncludedPrice >= 40000) {
            return PRICE.ID.BW_40_50K;
          }
          if (taxIncludedPrice >= 30000) {
            return PRICE.ID.BW_30_40K;
          }
          return PRICE.ID.BW_20_30K;

        case TERM.ID.YEAR_1:
          // 1年：4万〜、5万〜、6万〜、7万〜、8万〜
          if (taxIncludedPrice >= 80000) {
            return PRICE.ID.OVER_80K;
          }
          if (taxIncludedPrice >= 70000) {
            return PRICE.ID.OVER_70K;
          }
          if (taxIncludedPrice >= 60000) {
            return PRICE.ID.OVER_60K;
          }
          if (taxIncludedPrice >= 50000) {
            return PRICE.ID.OVER_50K;
          }
          return PRICE.ID.BW_40_50K;
      }
    }
  }

  /** 定員(最大) */
  export namespace CAPACITY {
    export enum ID {
      /** 4名以下 */
      UNDER_4 = 4,
      /** 5名 */
      SEATER_5 = 5,
      /** 6名 */
      SEATER_6 = 6,
      /** 7名 */
      SEATER_7 = 7,
      /** 8名以上 */
      OVER_8 = 8,
    }
    /** 表示名
     * 順序がリスト順番に相当
     */
    export const NAME: { [key: number]: string } = {
      4: '4人以下',
      5: '5人',
      6: '6人',
      7: '7人',
      8: '8人以上',
    };

    export function DETAIL_VIEW_STRING(
      has8SeatOrMore: boolean,
      has7Seat: boolean,
      has6Seat: boolean,
      has5Seat: boolean,
      has4SeatOrLess: boolean,
    ): string {
      if (
        !has8SeatOrMore
        && !has7Seat
        && !has6Seat
        && !has5Seat
        && !has4SeatOrLess
      ) {
        return '0人';
      }

      let maxCapacity = 0;
      if (has8SeatOrMore) {
        maxCapacity = NEWCAR_CONST.CAPACITY.ID.OVER_8;
      } else if (has7Seat) {
        maxCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_7;
      } else if (has6Seat) {
        maxCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_6;
      } else if (has5Seat) {
        maxCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_5;
      } else {
        maxCapacity = NEWCAR_CONST.CAPACITY.ID.UNDER_4;
      }

      let minCapacity = 0;
      if (has4SeatOrLess) {
        minCapacity = NEWCAR_CONST.CAPACITY.ID.UNDER_4;
      } else if (has5Seat) {
        minCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_5;
      } else if (has6Seat) {
        minCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_6;
      } else if (has7Seat) {
        minCapacity = NEWCAR_CONST.CAPACITY.ID.SEATER_7;
      } else {
        minCapacity = NEWCAR_CONST.CAPACITY.ID.OVER_8;
      }
      if (maxCapacity === minCapacity) {
        return NEWCAR_CONST.CAPACITY.NAME[maxCapacity];
      }
      return `${minCapacity}〜${maxCapacity}人`;
    }
  }

  /** 検索条件 */
  export namespace SEARCH_OPTION {
    export enum ID {
      /** ハイブリッド有無 */
      HAS_HYBRID = 'h',
      /** 4WD有無 */
      HAS_4WD = '4',
      /** MT有無 */
      HAS_MT = 'm',
      /** 電動スライドドア */
      HAS_POWER_SLIDE_DOOR = 'p',
      /** キーレスエントリー */
      HAS_KEYLESS_ENTRY = 'k',
      /** スマートキー */
      HAS_SMART_KEY = 's',
      /** クルーズコントロール */
      HAS_CRUISE_CTRL = 'c',
      /** 自動ブレーキ */
      HAS_AUTO_BRAKE = 'a',
      /** ペダル踏み間違い時サポートブレーキ */
      HAS_SUPPORT_PEDAL = 'f',
      /** 車線はみ出しアラート */
      HAS_SUPPORT_LANE = 'l',
    }
    export function NAME(key: string) :'ハイブリッド' | '4WD' | 'MTあり' | '電動スライドドア' | 'キーレスエントリー' | 'スマートキー' | 'クルーズコントロール' | '自動ブレーキ' | '踏み間違いブレーキ' | '車線はみ出しアラート' | '' {
      switch (key) {
        case 'h':
          return 'ハイブリッド';
        case '4':
          return '4WD';
        case 'm':
          return 'MTあり';
        case 'p':
          return '電動スライドドア';
        case 'k':
          return 'キーレスエントリー';
        case 's':
          return 'スマートキー';
        case 'c':
          return 'クルーズコントロール';
        case 'a':
          return '自動ブレーキ';
        case 'f':
          return '踏み間違いブレーキ';
        case 'l':
          return '車線はみ出しアラート';
      }
      return '';
    }
  }

  /** オプション */
  export namespace Option {
    /**
     * オプションの種類
     * @export
     * @enum {number}
     */
    export enum Type {
      /** カーナビ */
      CAR_NAVI = 0,
      /** ETC */
      ETC = 1,
      /** バックカメラ */
      BACK_CAMERA = 2,
      /** フロアマット */
      FLOOR_MAT = 3,
      /** ドアバイザー */
      DOOR_VISOR = 4,
      /** 希望ナンバー */
      WANT_NO = 5,
    }
  }

  /** メンテナンス */
  export namespace MAINTENANCE {
    /**
     * メンテプラン
     * @export
     * @enum {number}
     */
    export enum PLAN {
      /** 未選択 */
      NOT_SELECTED = 0,
      /** ゴールド */
      GOLD = 1,
      /** シルバー */
      SILVER = 2,
      /** なし */
      NONE = 3,
      /** プラチナ */
      PLATINUM = 4,
    }

    /**
     * 適合タイプ
     * @export
     * @enum {number}
     */
    export enum TYPE {
      /** 非適合 */
      NOT_APPLICABLE = 'notapplicable',
      /** 標準 */
      STANDARD = 'standard',
      /** 軽自動車 */
      MINICAR = 'minicar',
    }

    /**
     * メンテナンスプラン表示名
     * @export
     * @param {PLAN} key
     * @returns {(string | undefined)}
     */
    export function PLAN_NAME(key: PLAN): string | undefined {
      switch (key) {
        case PLAN.NONE:
          return 'メンテプランなし';
        case PLAN.SILVER:
          return 'シルバーメンテプラン';
        case PLAN.GOLD:
          return 'ゴールドメンテプラン';
        case PLAN.PLATINUM:
          return 'プラチナメンテプラン';
      }
    }

    /**
     * メンテナンスプラン表示名（省略
     * @export
     * @param {PLAN} key
     * @returns {(string | undefined)}
     */
    export function PLAN_SHORT_NAME(key: PLAN): string | undefined {
      switch (key) {
        case PLAN.NONE:
          return 'なし';
        case PLAN.SILVER:
          return 'シルバー';
        case PLAN.GOLD:
          return 'ゴールド';
        case PLAN.PLATINUM:
          return 'プラチナ';
      }
    }

    /**
     * メンテナンスプラン概要
     * @export
     * @param {PLAN} key
     * @returns {(string | undefined)}
     */
    export function PLAN_OVERVIEW(key: PLAN): string | undefined {
      switch (key) {
        case PLAN.NONE: return '';
        case PLAN.SILVER: return '必要最低限なメンテナンス費用の補償に車検費用などをセットしたベーシックなプランです';
        case PLAN.GOLD: return 'メンテナンス費用補償項目がシルバーメンテプランよりも充実。毎日のカーライフに安心なスタンダードなプランです';
        case PLAN.PLATINUM: return 'メンテナンス費用補償＋メーカー保証終了後も契約満了日まで保証を延長。長く安心して車に乗りたい方にオススメ';
      }
    }
  }

  /** 燃費 */
  export namespace MILEAGE {
    /**
     * 燃費
     * @export
     * @enum {number}
     */
    export enum ID {
      /** jc08 */
      JC08 = 1,
      /** wltc */
      WLTC = 2,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      1: 'JC08',
      2: 'WLTC',
    };

    export function WHICH_USE(
      jc08: string | undefined,
      wltc: string | undefined,
    ): { name: string; value: string } {
      if (wltc) {
        return { name: NAME[ID.WLTC], value: wltc };
      }
      if (jc08) {
        return { name: NAME[ID.JC08], value: jc08 };
      }
      return { name: '', value: '' };
    }
  }

  /** サポカー */
  export namespace SUPPORT_CAR {
    /**
     * タイプ
     * @export
     * @enum {number}
     */
    export enum TYPE {
      /** 非適合 */
      NOT_APPLICABLE = 'notapplicable',
      /** サポカー標準 */
      NOMAL = 'nomal',
      /** ベーシック */
      BASIC = 'basic',
      /** ベーシック＋ */
      PLUS = 'plus',
      /** ワイド */
      WIDE = 'wide',
    }
  }

  /** 並び替え */
  export enum SORT {
    /** 料金順 */
    PRICE = 'price',
    /** 人気順 */
    POP = 'popularity',
    /** 50音順 */
    ATOZ = 'atoz',
  }

  /** 並び替えの名前 */
  export const SORT_NAME = {
    /** 料金順 */
    [SORT.PRICE]: '安い順',
    /** 人気順 */
    [SORT.POP]: '人気順',
    /** 50音順 */
    [SORT.ATOZ]: '50音順',
  };

  /** 駆動 */
  export namespace KUDOU {
    export enum ID {
      /** 4名以下 */
      IS_2WD = 2,
      /** 5名 */
      IS_4WD = 4,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      2: '2WD',
      4: '4WD',
    };
    export function NAME_TO_ID(name?: string):ID {
      if (name === undefined) {
        return ID.IS_2WD;
      }
      if (name === NAME[ID.IS_4WD]) {
        return ID.IS_4WD;
      }
      return ID.IS_2WD;
    }
  }

  /** エンジン */
  export namespace ENGINE {
    export enum ID {
      /** ガソリン */
      IS_GAS = 1,
      /** ハイブリッド */
      IS_HYBRID = 2,
      /** ターボ */
      IS_TURBO = 3,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      1: 'ガソリン',
      2: 'ハイブリッド',
      3: 'ターボ',
    };
    export function NAME_TO_ID(name: string):ID {
      if (name === NAME[ID.IS_TURBO]) {
        return ID.IS_TURBO;
      }
      if (name === NAME[ID.IS_HYBRID]) {
        return ID.IS_HYBRID;
      }
      return ID.IS_GAS;
    }
  }

  /** ミッション */
  export namespace MISSION {
    export enum ID {
      NOT_FOUND = 0,
      IS_4DAT = 1,
      IS_4FAT = 2,
      IS_5DAT = 3,
      IS_5FAT = 4,
      IS_5FMT = 5,
      IS_6DAT = 6,
      IS_6FAT = 7,
      IS_6FMT = 8,
      IS_7DAT = 9,
      IS_7FAT = 10,
      IS_8DAT = 11,
      IS_8FAT = 12,
      IS_AT = 13,
      IS_CCVT = 14,
      IS_DCVT = 15,
      IS_FCVT = 16,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      0: '',
      1: '4DAT',
      2: '4FAT',
      3: '5DAT',
      4: '5FAT',
      5: '5FMT',
      6: '6DAT',
      7: '6FAT',
      8: '6FMT',
      9: '7DAT',
      10: '7FAT',
      11: '8DAT',
      12: '8FAT',
      13: 'AT',
      14: 'CCVT',
      15: 'DCVT',
      16: 'FCVT',
    };

    export function NAME_TO_ID(name?: string): MISSION.ID {
      if (name === undefined) {
        return ID.NOT_FOUND;
      }
      for (const value in ID) {
        if (!isNaN(Number(value))) {
          if (name === NAME[value]) {
            return Number(value);
          }
        }
      }
      return ID.NOT_FOUND;
    }
  }

  // removedSafetySystem
  export namespace REMOVE_SAFETY_SYSTEM {
    export enum ID {
      NOT_FOUND = 0,
      /** Honda SENSING非装着 */
      IS_HONDA = 1,
      /** 衝突被害軽減ブレーキ非装着車 */
      IS_SYOUTOTU = 2,
      /** セーフティパッケージレス仕様車 */
      IS_SAFTY = 3,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      0: '',
      1: 'Honda SENSING非装着',
      2: '衝突被害軽減ブレーキ非装着車',
      3: 'セーフティパッケージレス仕様車',
    };

    export function NAME_TO_ID(name: string):ID {
      if (name === NAME[ID.IS_HONDA]) {
        return ID.IS_HONDA;
      }
      if (name === NAME[ID.IS_SYOUTOTU]) {
        return ID.IS_SYOUTOTU;
      }
      if (name === NAME[ID.IS_SAFTY]) {
        return ID.IS_SAFTY;
      }
      return ID.NOT_FOUND;
    }
  }

  /** パワースライドドア */
  export namespace POWER_SLIDE_DOOR {
    export enum ID {
      NOT_FOUND = 0,
      BOTH_DEFAULT = 1,
      LEFT_DEFAULT = 2,
      MAKER_OPTION = 3,
      NOT_SUPPORT = 4,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      0: '',
      1: '両側標準',
      2: '左側標準',
      3: 'メーカー',
      4: 'なし',
    };

    export function NAME_TO_ID(name?: string): POWER_SLIDE_DOOR.ID {
      for (const value in ID) {
        if (!isNaN(Number(value))) {
          if (name === NAME[value]) {
            return Number(value);
          }
        }
      }
      return ID.NOT_FOUND;
    }
  }

  /** キーレスエントリー、スマートキー、クルーズコントロール、自動ブレーキ、踏み間違いサポート、車線逸脱アラートの共通 */
  export namespace COMMON_OPTIONS {
    export enum ID {
      NOT_FOUND = 0,
      DEFAULT = 1,
      MAKER = 2,
      NOT_SUPPORT = 3,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      0: '',
      1: '標準',
      2: 'メーカー',
      3: 'なし',
    };

    export function NAME_TO_ID(name?: string): COMMON_OPTIONS.ID {
      for (const value in ID) {
        if (!isNaN(Number(value))) {
          if (name === NAME[value]) {
            return Number(value);
          }
        }
      }
      return ID.NOT_FOUND;
    }
  }

  /* 料金シミュレーション モーダル名 */
  export namespace SIMULATION_MODAL {
    export enum NAME {
      TERM = 'modalTerm',
      GRADE = 'modalGrade',
      BODY_COLOR = 'modalBodyColor',
      OPTION = 'modalOption',
      MAINRE = 'modalMainte',
      RESULT = 'modalResult',
    }
    export const SP: { [key: number]: string } = {
      1: SIMULATION_MODAL.NAME.TERM,
      2: SIMULATION_MODAL.NAME.GRADE,
      3: SIMULATION_MODAL.NAME.BODY_COLOR,
      4: SIMULATION_MODAL.NAME.OPTION,
      5: SIMULATION_MODAL.NAME.MAINRE,
      6: SIMULATION_MODAL.NAME.RESULT,
    };
    export const ORIGINAL: { [key: number]: string } = {
      1: SIMULATION_MODAL.NAME.GRADE,
      2: SIMULATION_MODAL.NAME.BODY_COLOR,
      3: SIMULATION_MODAL.NAME.OPTION,
      4: SIMULATION_MODAL.NAME.MAINRE,
    };
    export const OTHER: { [key: number]: string } = {
      1: SIMULATION_MODAL.NAME.BODY_COLOR,
      2: SIMULATION_MODAL.NAME.OPTION,
    };
  }

  /** PC版料金シミュレーション用 プラン種別 */
  export namespace SIMULATION_PLAN {
    export enum ID {
      SIMPLE = 1,
      RECOMMEND = 2,
      RICH = 3,
      ORIGINAL = 4,
      RECOMMEND_SP = 5,
      TRIAL = 6,
    }
    /**
     * 表示名
     */
    export const NAME: { [key: number]: string } = {
      1: 'シンプルプラン',
      2: 'おすすめプラン',
      3: 'よくばりプラン',
      4: 'オリジナルプラン',
      5: 'オススメのプラン',
    };
  }
  export namespace PLAN_PATTERN {
    export enum NAME {
      LP_QUICK = 'LPクイック',
      MAG_QUICK = 'マガジンクイック',
      OTAMESHI = 'お試し審査',
      MAIN_QUICK = '本体クイック',
      MAIN_SIMPLE = '本体シンプル',
      MAIN_RICH = '本体よくばり',
      MAIN_LP = '本体LP',
      MAIN_RECOMMEND_PC = 'PC本体おすすめ',
      MAIN_RECOMMEND_SP = '本体オススメ',
      MAIN_TRIAL = '相談申し込み', // TODO Vue版にしかない
      MAIN_ORIGINAL = '本体オリジナル',
      MAIN_QUICK_DELIVERY = '本体即納',
      LP_QUICK_DELIVERY = 'LP即納',
      CONSULT = '相談申し込み', // TODO Vue版にしかない
      TRIAL = '仮申し込み', // TODO Vue版にしかない
      DIRECTTRIAL = '直接フォーム申し込み', // TODO Vue版にしかない
    }
    export enum QUERY {
      DELIVERY = 'deli',
      LP_WOMAN = 'woman',
      MAIN_LP = '/mainLp/',
      MAG_QUICK = 'マガジン',
      LP = '/lp/',
      OTAMESHI = 'otameshi',
      QUICK = 'quick',
      CONSULT = 'consult',
      TRIAL = 'trial',
    }
  }

  /** 申込タイプ */
  export namespace APPLICATION_TYPE {
    /** 流入元分類 */
    export const REFERRER_TYPE = {
      MAIN: 'main',
    };
    /** 車種状態 */
    export const CAR_CONDITION = {
      NEW_CAR: 'NewCar',
    };
    /** 車種選択 */
    export const CAR_SELECT = {
      CAR_MODEL: 'CarModel',
      TYPE: 'Type',
      CONSULTATION: 'Consultation',
    };
    /** プラン詳細 */
    export const PLAN_DETAIL = {
      INDIVIDUAL_COST_SIMULATIONS: 'IndividualCostSimulations',
      AFFORDABLE_KEI: 'AffordableKei',
      COMFORTABLE_KEI: 'ComfortableKei',
    };
    export const PLAN_DETAIL_NAME = {
      AFFORDABLE_KEI: 'お手軽タイプ軽自動車',
      COMFORTABLE_KEI: '安心快適タイプ軽自動車',
    };
  }

  export namespace CAMPAIGNS {
    export enum NAME {
      CP_MASCOT = 'cp_mascot',
    }
  }
}

export default NEWCAR_CONST;
